import { template } from "@ember/template-compiler";
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import { t } from 'ember-intl';
export default template(`
  <header>
    <h1>{{t "components.autonomous-courses.title"}}</h1>
    <PixButtonLink @route="authenticated.autonomous-courses.new" @variant="secondary">
      {{t "components.autonomous-courses.actions.new"}}
    </PixButtonLink>
  </header>

  <main class="page-body">
    <section class="page-section">
      {{yield}}
    </section>
  </main>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
