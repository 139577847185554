import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { pageTitle } from 'ember-page-title';
import Breadcrumb from '../breadcrumb';
import TechnicalInformations from './technical-informations';
import UserInformations from './user-informations';
export default class NewAutonomousCourse extends Component {
    @service
    intl;
    @tracked
    submitting = false;
    constructor(){
        super(...arguments);
    }
    get translatedTitle() {
        return this.intl.t('components.autonomous-courses.new.title');
    }
    @action
    updateAutonomousCourseValue(key1, event1) {
        this.args.autonomousCourse[key1] = event1.target.value;
    }
    @action
    selectTargetProfile(targetProfileId1) {
        this.args.autonomousCourse.targetProfileId = targetProfileId1;
    }
    get targetProfileListOptions() {
        const options1 = this.args.targetProfiles.map((targetProfile1)=>({
                value: targetProfile1.id,
                label: targetProfile1.name,
                category: targetProfile1.category,
                order: 'OTHER' === targetProfile1.category ? 1 : 0
            }));
        options1.sort((targetProfileA1, targetProfileB1)=>{
            if (targetProfileA1.order !== targetProfileB1.order) {
                return targetProfileA1.order - targetProfileB1.order;
            }
            if (targetProfileA1.category !== targetProfileB1.category) {
                return targetProfileA1.category.localeCompare(targetProfileB1.category);
            }
            return targetProfileA1.label.localeCompare(targetProfileB1.label);
        });
        return options1;
    }
    @action
    async onSubmit(event1) {
        const autonomousCourse1 = {
            internalTitle: this.args.autonomousCourse.internalTitle,
            publicTitle: this.args.autonomousCourse.publicTitle,
            targetProfileId: this.args.autonomousCourse.targetProfileId,
            customLandingPageText: this.args.autonomousCourse.customLandingPageText
        };
        try {
            this.submitting = true;
            await this.args.onSubmit(event1, autonomousCourse1);
        } finally{
            this.submitting = false;
        }
    }
    static{
        template(`
    {{pageTitle this.translatedTitle}}

    <header>
      <Breadcrumb @currentPageLabel="Nouveau parcours autonome" />
    </header>

    <main class="main-admin-form">
      <form class="admin-form" {{on "submit" this.onSubmit}}>
        <p class="admin-form__mandatory-text">
          {{t "common.forms.mandatory-fields" htmlSafe=true}}
        </p>
        <section class="admin-form__content admin-form__content--with-counters">
          <TechnicalInformations
            @updateAutonomousCourseValue={{this.updateAutonomousCourseValue}}
            @targetProfileListOptions={{this.targetProfileListOptions}}
            @selectTargetProfile={{this.selectTargetProfile}}
            @autonomousCourse={{@autonomousCourse}}
            @errors={{@errors}}
          />
          <UserInformations @updateAutonomousCourseValue={{this.updateAutonomousCourseValue}} />
        </section>
        <section class="admin-form__actions">
          <PixButton @variant="secondary" @size="large" @triggerAction={{@onCancel}}>
            {{t "common.actions.cancel"}}
          </PixButton>
          <PixButton
            @variant="success"
            @size="large"
            @type="submit"
            @isLoading={{this.submitting}}
            @triggerAction={{this.noop}}
          >
            {{t "components.autonomous-courses.new.create-autonomous-course"}}
          </PixButton>
        </section>
      </form>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
