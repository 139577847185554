import { template } from "@ember/template-compiler";
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
import { scrollToElement } from 'pix-admin/modifiers/scroll-to';
import AdministrationBlockLayout from '../block-layout';
import DownloadTemplate from '../download-template';
export default class UpsertQuestsInBatch extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    session;
    @service
    fileSaver;
    @service
    errorResponseHandler;
    @tracked
    errors = null;
    @action
    async upsertQuestsInBatch(files1) {
        let response1;
        this.errors = null;
        try {
            const fileContent1 = files1[0];
            const token1 = this.session.data.authenticated.access_token;
            response1 = await window.fetch(`${ENV.APP.API_HOST}/api/admin/quests`, {
                headers: {
                    Authorization: `Bearer ${token1}`,
                    'Content-Type': 'text/csv',
                    Accept: 'application/json'
                },
                method: 'POST',
                body: fileContent1
            });
            if (response1.ok) {
                this.pixToast.sendSuccessNotification({
                    message: this.intl.t('components.administration.upsert-quests-in-batch.notifications.success')
                });
                return;
            } else {
                const responseJson1 = await response1.json();
                const { errors: responseErrors1 } = responseJson1;
                if (isJSONAPIError(responseErrors1)) {
                    this.errors = responseErrors1;
                } else {
                    this.errorResponseHandler.notify(responseJson1, undefined, true);
                }
            }
        } catch (err1) {
            console.error(err1);
            this.pixToast.sendErrorNotification({
                message: this.intl.t('common.notifications.generic-error')
            });
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.upsert-quests-in-batch.title"}}
      @description={{t "components.administration.upsert-quests-in-batch.description"}}
      @actionsClass="upsert-quests-in-batch__actions"
      class="upsert-quests-in-batch"
    >
      <div class="upsert-quests-in-batch__buttons">
        <DownloadTemplate @url="/api/admin/quests/template">
          <PixButtonUpload
            @id="quests-batch-update-file-upload"
            @onChange={{this.upsertQuestsInBatch}}
            @variant="primary"
            accept=".csv"
          >
            {{t "components.administration.upsert-quests-in-batch.upload-button"}}
          </PixButtonUpload>
        </DownloadTemplate>

        <PixButtonLink @iconBefore="cogsMagic" @route="authenticated.quest-creator" @variant="secondary">
          {{t "components.administration.upsert-quests-in-batch.quest-creator"}}
        </PixButtonLink>
      </div>
      {{#if this.errors}}
        <PixNotificationAlert @withIcon={{true}} @type="error" class="upsert-quests-in-batch__errors">
          <ul>
            {{#each this.errors as |error|}}
              <li class="upsert-quests-in-batch__error" {{scrollToElement}}>
                <span>{{error.detail}}</span>
                <pre class="upsert-quests-in-batch__json">{{transformMetaToJSON error}}</pre>
              </li>
            {{/each}}
          </ul>
        </PixNotificationAlert>
      {{/if}}
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function isEmpty(value1) {
    return Array.isArray(value1) && value1.length === 0;
}
function isJSONAPIError(errors1) {
    return !isEmpty(errors1) && errors1.every((error1)=>error1.title);
}
function transformMetaToJSON(error1) {
    if (!error1.meta.data) return '';
    return JSON.stringify(error1.meta.data, undefined, 2);
}
