import { template } from "@ember/template-compiler";
import PixBreadcrumb from '@1024pix/pix-ui/components/pix-breadcrumb';
import Component from '@glimmer/component';
export default class Header extends Component {
    get links() {
        return [
            {
                route: 'authenticated.complementary-certifications.list',
                label: 'Toutes les certifications complémentaires'
            },
            {
                label: this.args.complementaryCertificationLabel
            }
        ];
    }
    static{
        template(`
    <header class="page-header">
      <PixBreadcrumb @links={{this.links}} />
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
