import { template } from "@ember/template-compiler";
import PixBreadcrumb from '@1024pix/pix-ui/components/pix-breadcrumb';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class Breadcrumb extends Component {
    @service
    router;
    get links() {
        const { localName: localName1 } = this.router.currentRoute;
        const links1 = [
            {
                route: 'authenticated.target-profiles.list',
                label: 'Tous les profils cibles'
            }
        ];
        if (this.args.targetProfile) {
            let badgeRoute1 = '';
            if (localName1 === 'badge') {
                badgeRoute1 = '.insights';
            }
            links1.push({
                route: `authenticated.target-profiles.target-profile${badgeRoute1}`,
                model: this.args.targetProfile.id,
                label: this.args.targetProfile.internalName
            });
        }
        links1.push({
            label: this.args.currentPageLabel
        });
        return links1;
    }
    static{
        template(`<PixBreadcrumb @links={{this.links}} />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
