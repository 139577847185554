import { template } from "@ember/template-compiler";
import PixTable from '@1024pix/pix-ui/components/pix-table';
import { t } from 'ember-intl';
import ListItem from './list-item';
export default template(`
  <PixTable
    @variant="admin"
    @caption={{t "components.organizations.children-list.table-name"}}
    @data={{@organizations}}
  >
    <:columns as |organization context|>
      <ListItem @organization={{organization}} @context={{context}} />
    </:columns>
  </PixTable>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
