import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixTable from '@1024pix/pix-ui/components/pix-table';
import PixTableColumn from '@1024pix/pix-ui/components/pix-table-column';
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import ConfirmPopup from '../confirm-popup';
export default class Badges extends Component {
    @tracked
    displayConfirm = false;
    @service
    store;
    @service
    pixToast;
    badgeIdToDelete;
    get hasBadges() {
        const badges1 = this.args.badges;
        return badges1 && badges1.length > 0;
    }
    @action
    toggleDisplayConfirm(badgeId1) {
        this.displayConfirm = !this.displayConfirm;
        this.badgeIdToDelete = badgeId1;
    }
    @action
    async deleteBadge() {
        let badge1;
        try {
            badge1 = this.store.peekRecord('badge', this.badgeIdToDelete);
            await badge1.destroyRecord();
            this.pixToast.sendSuccessNotification({
                message: 'Le badge a été supprimé avec succès.'
            });
        } catch (error1) {
            this.pixToast.sendErrorNotification({
                message: error1.errors[0].detail
            });
            badge1.rollbackAttributes();
        }
        this.toggleDisplayConfirm();
    }
    static{
        template(`
    {{#if this.hasBadges}}
      <PixTable
        @variant="admin"
        @data={{@badges}}
        @caption={{t "components.target-profiles.badges.table.caption"}}
        class="table insights-section__badge-table"
      >
        <:columns as |badge context|>
          <PixTableColumn @context={{context}}>
            <:header>
              ID
            </:header>
            <:cell>
              <LinkTo
                @route="authenticated.target-profiles.target-profile.badges.badge"
                @model={{badge.id}}
                aria-label="Voir le détail du badge ID {{badge.id}}"
              >
                {{badge.id}}
              </LinkTo>
            </:cell>
          </PixTableColumn>
          <PixTableColumn @context={{context}} class="badges-table-body__image">
            <:header>
              Image
            </:header>
            <:cell>
              <img src={{badge.imageUrl}} alt={{badge.altMessage}} />
            </:cell>
          </PixTableColumn>
          <PixTableColumn @context={{context}} class="break-word">
            <:header>
              Clé
            </:header>
            <:cell>
              {{badge.key}}
            </:cell>
          </PixTableColumn>
          <PixTableColumn @context={{context}} class="break-word">
            <:header>
              Nom
            </:header>
            <:cell>
              {{badge.title}}
            </:cell>
          </PixTableColumn>
          <PixTableColumn @context={{context}} class="break-word">
            <:header>
              Message
            </:header>
            <:cell>
              {{badge.message}}
            </:cell>
          </PixTableColumn>
          <PixTableColumn @context={{context}} class="badges-table-header__parameters">
            <:header>
              <PixTooltip @isWide={{true}}>
                <:triggerElement>
                  Paramètres
                  <PixIcon @name="help" />
                </:triggerElement>
                <:tooltip>
                  {{t "components.badges.tooltips.parameters" htmlSafe=true}}
                </:tooltip>
              </PixTooltip>
            </:header>
            <:cell>
              <div class="badges-table-body__content">
                <PixTag class="badges-table-body__tag" @color={{if badge.isAlwaysVisible "tertiary" "error"}}>
                  <PixIcon @name={{if badge.isAlwaysVisible "check" "close"}} />
                  {{if badge.isAlwaysVisible "En lacune" "Pas en lacune"}}
                </PixTag>
                <PixTag class="badges-table-body__tag" @color={{if badge.isCertifiable "success" "error"}}>
                  <PixIcon @name={{if badge.isCertifiable "check" "close"}} />
                  {{if badge.isCertifiable "Certifiable" "Pas certifiable"}}
                </PixTag>
              </div>
            </:cell>
          </PixTableColumn>
          <PixTableColumn @context={{context}}>
            <:header>
              Actions
            </:header>
            <:cell>
              <div class="badges-table-body__content">
                <PixButtonLink
                  @variant="secondary"
                  @route="authenticated.target-profiles.target-profile.badges.badge"
                  @size="small"
                  @model={{badge.id}}
                  aria-label="Voir le détail du badge {{badge.title}}"
                >
                  Voir le détail
                </PixButtonLink>
                <PixButton
                  @size="small"
                  @variant="error"
                  @triggerAction={{fn this.toggleDisplayConfirm badge.id}}
                  class="badges-table-actions-delete"
                  @iconBefore="delete"
                  aria-label="Supprimer le badge {{badge.title}}"
                >
                  Supprimer
                </PixButton>
              </div>
            </:cell>
          </PixTableColumn>
        </:columns>
      </PixTable>
    {{else}}
      <div class="table__empty">Aucun badge associé</div>
    {{/if}}

    <ConfirmPopup
      @message="Êtes-vous sûr de vouloir supprimer ce badge ? (Uniquement si le badge n'a pas encore été assigné)"
      @title="Suppression d'un badge"
      @submitTitle="Confirmer"
      @confirm={{fn this.deleteBadge this.badgeIdToDelete}}
      @cancel={{this.toggleDisplayConfirm}}
      @show={{this.displayConfirm}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
