import { template } from "@ember/template-compiler";
import PixBreadcrumb from '@1024pix/pix-ui/components/pix-breadcrumb';
import Component from '@glimmer/component';
export default class Breadcrumb extends Component {
    get links() {
        return [
            {
                route: 'authenticated.trainings.list',
                label: 'Tous les contenus formatifs'
            },
            {
                label: this.args.currentPageLabel
            }
        ];
    }
    static{
        template(`<PixBreadcrumb @links={{this.links}} />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
