import { template } from "@ember/template-compiler";
import PixBreadcrumb from '@1024pix/pix-ui/components/pix-breadcrumb';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class Breadcrumb extends Component {
    @service
    intl;
    get links() {
        return [
            {
                route: 'authenticated.autonomous-courses',
                label: this.intl.t(`components.autonomous-courses.title`)
            },
            {
                label: this.args.currentPageLabel
            }
        ];
    }
    static{
        template(`<PixBreadcrumb @links={{this.links}} />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
