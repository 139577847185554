import { template } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import Area from '../common/tubes-details/area';
export default template(`
  <PixBlock @variant="admin">
    {{#each @areas as |area|}}
      <Area
        @title={{area.title}}
        @color={{area.color}}
        @competences={{area.competences}}
        @displayDeviceCompatibility={{true}}
        @displaySkillDifficultyAvailability={{true}}
      />
    {{else}}
      <section class="page-section">
        <div class="table__empty">Profil cible vide.</div>
      </section>
    {{/each}}
  </PixBlock>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
