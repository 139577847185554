import { template } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixTextarea from '@1024pix/pix-ui/components/pix-textarea';
import PixToggleButton from '@1024pix/pix-ui/components/pix-toggle-button';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { objectConfigurations } from 'pix-admin/components/quests/requirements/object/object-configuration.js';
import PageTitle from '../ui/page-title';
import SnippetList from './snippets/list';
const LOCAL_STORAGE_KEY = 'QUEST_REQUIREMENT_SNIPPETS';
export default class QuestForm extends Component {
    @tracked
    name = '';
    @tracked
    rewardType = 'attestations';
    @tracked
    rewardId = '';
    @tracked
    eligibilityRequirementsStr = '';
    @tracked
    successRequirementsStr = '';
    @tracked
    switchRequirements = true;
    @service
    router;
    @service
    pixToast;
    get requirementsStr() {
        return !this.switchRequirements ? this.successRequirementsStr : this.eligibilityRequirementsStr;
    }
    get requirementState() {
        return !this.switchRequirements ? 'Succès' : 'Éligiblités';
    }
    @action
    updateName(event1) {
        this.name = event1.target.value;
    }
    @action
    updateRewardType(event1) {
        this.rewardType = event1.target.value;
    }
    @action
    updateRewardId(event1) {
        this.rewardId = event1.target.value;
    }
    @action
    updateRequirementsStr(event1) {
        if (!this.switchRequirements) {
            this.successRequirementsStr = event1.target.value;
        } else {
            this.eligibilityRequirementsStr = event1.target.value;
        }
    }
    @action
    appendToRequirementsStr(str1) {
        if (!this.switchRequirements) {
            this.successRequirementsStr += str1;
        } else {
            this.eligibilityRequirementsStr += str1;
        }
    }
    @action
    onChangeRequirements() {
        this.switchRequirements = !this.switchRequirements;
    }
    @action
    async copyEligibilityRequirementsToClipboard() {
        try {
            const snippets1 = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {
                objectRequirementsByLabel: {}
            };
            const eligibilityRequirements1 = this.buildArrayRequirement(this.eligibilityRequirementsStr, snippets1.objectRequirementsByLabel);
            const successRequirements1 = this.buildArrayRequirement(this.successRequirementsStr, snippets1.objectRequirementsByLabel);
            const questToJson1 = JSON.stringify({
                rewardId: parseInt(this.rewardId),
                rewardType: this.rewardType,
                eligibilityRequirements: eligibilityRequirements1,
                successRequirements: successRequirements1
            });
            console.log(questToJson1);
            await navigator.clipboard.writeText(questToJson1);
            this.pixToast.sendSuccessNotification({
                message: 'Votre quête a été copié dans votre presse papier ou presque.'
            });
        } catch (error1) {
            console.log(error1);
            this.pixToast.sendErrorNotification({
                message: 'Votre quête a une erreur quelque part.'
            });
        }
    }
    /*
      On parcourt la chaîne de caractères. Quand on tombe sur un mot qu'on "reconnaît" on fait une action.

      Fonction inspirée des algorithmes d'évaluation d'expression arithmétique, dans notre cas la notation dite "préfixe"
      On a l'habitude de lire les expressions arithmétiques dans une notation dite "infixe" :
      x + y ---> x et y sont des opérandes, et le jeton d'opération se trouve au milieu
      En notation préfixe, ça donne ceci :
      + x y ---> le jeton d'opération se trouve au début
      En programmation, cette notation est plus facile à traiter. Il existe notamment une technique connue s'appuyant
      sur l'utilisation de piles.
      Plus d'infos : https://zanotti.univ-tln.fr/ALGO/II/Polonaise.html
      On remarquera que l'expression construite pour les requirements d'éligibilité dans le formulaire ressemble beaucoup à
      une notation "préfixe" :
      ALL(ONE-OF(A,B),C) ---> Le jeton d'opération (all ou one-of) se trouve au début, et en arguments
      on trouve la liste des opérandes.
   */ buildArrayRequirement(str1, objectRequirementsByLabel1) {
        // Dictionnaire des "mots" qui correspondent à des requirements feuilles
        // qu'on pourrait retrouver dans la formule
        const snippetNames1 = Object.keys(objectRequirementsByLabel1);
        const composeStack1 = [];
        let currentWord1 = '';
        let latestCompletedCompose1;
        for (const char1 of str1){
            currentWord1 += char1;
            if (currentWord1 === ')') {
                // Le requirement compose en cours est fini
                // On le sort de la pile et on l'ajoute dans le requirement compose juste en dessous
                if (composeStack1.length > 0) {
                    const stack1 = composeStack1.at(-2);
                    if (stack1 && stack1.requirement_type === 'compose') {
                        latestCompletedCompose1 = composeStack1.pop();
                        composeStack1.at(-1).data.push(latestCompletedCompose1);
                    } else {
                        latestCompletedCompose1 = composeStack1;
                    }
                }
                currentWord1 = '';
            } else if (currentWord1 === ',') {
                // à ignorer car on passe à l'opérande suivant
                currentWord1 = '';
            } else if (currentWord1 === 'all(') {
                // Initialiser un requirement compose "all" et ajouter en haut de la pile
                // Il devient le requirement compose en cours de traitement
                const composeAll1 = {
                    requirement_type: 'compose',
                    comparison: 'all',
                    data: []
                };
                composeStack1.push(composeAll1);
                currentWord1 = '';
            } else if (currentWord1 === 'one-of(') {
                // Initialiser un requirement compose "one-of" et ajouter en haut de la pile
                // Il devient le requirement compose en cours de traitement
                const composeOneOf1 = {
                    requirement_type: 'compose',
                    comparison: 'one-of',
                    data: []
                };
                composeStack1.push(composeOneOf1);
                currentWord1 = '';
            } else if (snippetNames1.includes(currentWord1)) {
                // Un opérande ! on l'ajoute au requirement compose en cours
                const requirement1 = objectRequirementsByLabel1[currentWord1];
                const formatDataQuest1 = objectConfigurations[requirement1.requirement_type].formatRequirement(requirement1);
                const stack1 = composeStack1.at(-1);
                stack1 && stack1.requirement_type === 'compose' ? composeStack1.at(-1).data.push(formatDataQuest1) : composeStack1.push(formatDataQuest1);
                currentWord1 = '';
            }
        }
        return latestCompletedCompose1 || composeStack1;
    }
    static{
        template(`
    <PageTitle>
      <:title>Création de la quête</:title>
    </PageTitle>

    <section class="quest-object-form">
      <PixBlock @variant="admin" class="quest-button-edition">
        <PixInput onchange={{this.updateName}} required={{true}}>
          <:label>Nom de la quête</:label>
        </PixInput>
        <PixInput onchange={{this.updateRewardType}} required={{true}}>
          <:label>Type de récompense</:label>
        </PixInput>
        <PixInput onchange={{this.updateRewardId}} required={{true}}>
          <:label>ID de récompense</:label>
        </PixInput>
      </PixBlock>

      <PixBlock @variant="admin" class="quest-button-edition quest-button-edition--column">
        <PixToggleButton @toggled={{this.switchRequirements}} @onChange={{this.onChangeRequirements}}>
          <:label>Mes requirements :</:label>
          <:viewA>Éligibilités</:viewA>
          <:viewB>Succès</:viewB>
        </PixToggleButton>

        <PixTextarea value={{this.requirementsStr}} {{on "change" this.updateRequirementsStr}} rows="15">
          <:label>Mes requirements ({{this.requirementState}})</:label>
        </PixTextarea>
      </PixBlock>

      <PixBlock @variant="admin" class="quest-button-edition quest-button-edition--column">
        <h2>Créateur de condition :</h2>
        <ul class="quest-button-edition__list">
          <li>
            <PixButton @size="small" @variant="secondary" @triggerAction={{fn this.appendToRequirementsStr "all("}}>
              all(
            </PixButton>
          </li>
          <li>
            <PixButton @size="small" @variant="secondary" @triggerAction={{fn this.appendToRequirementsStr "one-of("}}>
              one-of(
            </PixButton>
          </li>
          <li>
            <PixButton @size="small" @variant="secondary" @triggerAction={{fn this.appendToRequirementsStr ")"}}>
              )
            </PixButton>
          </li>
          <li>
            <PixButton @size="small" @variant="secondary" @triggerAction={{fn this.appendToRequirementsStr ","}}>
              ,
            </PixButton>
          </li>
        </ul>

        <h2>Mes snippets :</h2>
        <SnippetList @triggerAction={{this.appendToRequirementsStr}} />
      </PixBlock>

      <div class="quest-button-edition__button">
        <PixButtonLink @route="authenticated.quest-new-or-edit-snippet" @size="small" @variant="primary">
          Créer ou modifier un snippet de requirement
        </PixButtonLink>

        <PixButton @size="small" @variant="success" @triggerAction={{this.copyEligibilityRequirementsToClipboard}}>
          Copiez le JSON de quête dans le presse-papiers
        </PixButton>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
