import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
const FRANCE_INSEE_CODE = '99100';
const INSEE_CODE_OPTION = 'insee';
const POSTAL_CODE_OPTION = 'postal';
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixModal from '@1024pix/pix-ui/components/pix-modal';
import PixRadioButton from '@1024pix/pix-ui/components/pix-radio-button';
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import pick from 'ember-composable-helpers/helpers/pick';
import EmberFlatpickr from 'ember-flatpickr/components/ember-flatpickr';
import set from 'ember-set-helper/helpers/set';
import { eq, or } from 'ember-truth-helpers';
export default class CandidateEditModal extends Component {
    @service
    store;
    @tracked
    firstName;
    @tracked
    lastName;
    @tracked
    birthdate;
    @tracked
    birthCity;
    @tracked
    sex;
    @tracked
    birthInseeCode;
    @tracked
    birthPostalCode;
    @tracked
    birthCountry;
    @tracked
    countries = [];
    @tracked
    selectedBirthGeoCodeOption;
    @tracked
    selectedCountryInseeCode;
    constructor(){
        super(...arguments);
        this._initForm();
    }
    get isBirthGeoCodeRequired() {
        return this._isFranceSelected();
    }
    get isInseeCodeOptionSelected() {
        return this.selectedBirthGeoCodeOption === INSEE_CODE_OPTION;
    }
    get isPostalCodeOptionSelected() {
        return this.selectedBirthGeoCodeOption === POSTAL_CODE_OPTION;
    }
    get isBirthInseeCodeRequired() {
        if (!this._isFranceSelected()) {
            return false;
        }
        return this.isInseeCodeOptionSelected;
    }
    get isBirthPostalCodeRequired() {
        if (!this._isFranceSelected()) {
            return false;
        }
        return this.isPostalCodeOptionSelected;
    }
    get isBirthCityRequired() {
        if (!this._isFranceSelected()) {
            return true;
        }
        return this.isPostalCodeOptionSelected;
    }
    get countryOptions() {
        return this.countries.map((country1)=>{
            return {
                label: country1.name,
                value: country1.code
            };
        });
    }
    get selectedCountryOption() {
        if (this.birthCountry === 'FRANCE') return FRANCE_INSEE_CODE;
        return this.selectedCountryInseeCode;
    }
    @action
    updateSexValue(sex1) {
        this.sex = sex1;
    }
    @action
    selectBirthGeoCodeOption(option1) {
        this.selectedBirthGeoCodeOption = option1;
        if (this.isInseeCodeOptionSelected) {
            this.birthCity = '';
            this.birthPostalCode = '';
        } else if (this.isPostalCodeOptionSelected) {
            this.birthInseeCode = '';
        }
    }
    @action
    updateBirthdate(_1, lastSelectedDateFormatted1) {
        this.birthdate = lastSelectedDateFormatted1;
    }
    @action
    async onFormSubmit(event1) {
        event1.preventDefault();
        const informationBeforeUpdate1 = this.args.candidate.getInformation();
        this.args.candidate.updateInformation({
            firstName: this.firstName,
            lastName: this.lastName,
            birthdate: this.birthdate,
            birthplace: this.birthCity,
            sex: this.sex,
            birthInseeCode: this.birthInseeCode,
            birthPostalCode: this.birthPostalCode,
            birthCountry: this.birthCountry
        });
        try {
            await this.args.onFormSubmit();
            this._initForm();
        } catch  {
            this.args.candidate.updateInformation(informationBeforeUpdate1);
        }
    }
    @action
    onCancelButtonsClicked() {
        this._initForm();
        this.args.onCancelButtonsClicked();
    }
    @action
    selectBirthCountry(value1) {
        this.selectedCountryInseeCode = value1;
        this.birthCountry = this._getCountryName();
        this.birthCity = '';
        this.birthPostalCode = '';
        if (this._isFranceSelected()) {
            this.birthInseeCode = '';
        } else {
            this.selectBirthGeoCodeOption(INSEE_CODE_OPTION);
            this.birthInseeCode = '99';
        }
    }
    async _initForm() {
        const candidate1 = this.args.candidate;
        this.firstName = candidate1.firstName;
        this.lastName = candidate1.lastName;
        this.birthdate = candidate1.birthdate;
        this.sex = candidate1.sex;
        this.birthCountry = candidate1.birthCountry;
        this._initBirthInformation(candidate1);
        this.countries = this.store.peekAll('country').length ? this.store.peekAll('country') : await this.store.findAll('country');
    }
    _initBirthInformation(candidate1) {
        this.selectedBirthGeoCodeOption = candidate1.birthInseeCode ? INSEE_CODE_OPTION : POSTAL_CODE_OPTION;
        this.selectedCountryInseeCode = candidate1.wasBornInFrance() ? FRANCE_INSEE_CODE : candidate1.birthInseeCode;
        if (candidate1.wasBornInFrance() && this.isInseeCodeOptionSelected) {
            this.birthCity = '';
        } else {
            this.birthCity = candidate1.birthplace;
        }
        if (this.isPostalCodeOptionSelected) {
            this.birthInseeCode = '';
        } else if (this._isFranceSelected()) {
            this.birthInseeCode = candidate1.birthInseeCode;
        } else {
            this.birthInseeCode = '99';
        }
        if (this.isPostalCodeOptionSelected) {
            this.birthPostalCode = candidate1.birthPostalCode;
        } else {
            this.birthPostalCode = '';
        }
    }
    _isFranceSelected() {
        return this.selectedCountryInseeCode === FRANCE_INSEE_CODE;
    }
    _getCountryName() {
        const country1 = this.countries.find((country1)=>country1.code === this.selectedCountryInseeCode);
        return country1.name;
    }
    static{
        template(`
    <PixModal
      @title="Modifier les informations du candidat"
      @onCloseButtonClick={{this.onCancelButtonsClicked}}
      @showModal={{@isDisplayed}}
    >
      <:content>
        <form id="candidate-edit-form" {{on "submit" this.onFormSubmit}}>
          <p class="candidate-edit-modal--content__required-fields-mention">
            Tous les champs sont obligatoires.
          </p>

          <div class="candidate-edit-modal--content__field">
            <PixInput
              class="input"
              @value={{this.lastName}}
              {{on "input" (pick "target.value" (set this "lastName"))}}
              required
            ><:label>Nom de famille</:label></PixInput>
          </div>

          <div class="candidate-edit-modal--content__field">
            <PixInput
              class="input"
              @value={{this.firstName}}
              required
              {{on "input" (pick "target.value" (set this "firstName"))}}
            ><:label>Prénom</:label></PixInput>
          </div>

          <div class="candidate-edit-modal--content__field-radio-button">
            Sexe
            <div class="radio-button-container">
              <PixRadioButton
                name="sex"
                checked={{if (eq this.sex "F") true}}
                {{on "change" (fn this.updateSexValue "F")}}
                required
              >
                <:label>Femme</:label>
              </PixRadioButton>
              <PixRadioButton
                name="sex"
                checked={{if (eq this.sex "M") true}}
                {{on "change" (fn this.updateSexValue "M")}}
                required
              >
                <:label>Homme</:label>
              </PixRadioButton>
            </div>
          </div>

          <div class="candidate-edit-modal--content__field">
            <label for="birthdate">
              Date de naissance
            </label>
            <EmberFlatpickr
              id="birthdate"
              @altFormat="d/m/Y"
              @altInput={{true}}
              @onChange={{this.updateBirthdate}}
              @dateFormat="Y-m-d"
              @locale="fr"
              @date={{or this.birthdate ""}}
            />
          </div>

          <div class="candidate-edit-modal--content__field">
            <PixSelect
              @label="Pays de naissance"
              @options={{this.countryOptions}}
              @onChange={{this.selectBirthCountry}}
              @value={{this.selectedCountryOption}}
              required
            >
              <:label>Pays de naissance</:label>
            </PixSelect>
          </div>

          {{#if this.isBirthGeoCodeRequired}}
            <div class="candidate-edit-modal--content__field-radio-button">
              Code géographique de naissance
              <div class="radio-button-container">
                <PixRadioButton
                  name="birth-geo-code-option"
                  checked={{if (eq this.selectedBirthGeoCodeOption "insee") true}}
                  {{on "change" (fn this.selectBirthGeoCodeOption "insee")}}
                  required
                >
                  <:label>Code INSEE</:label>
                </PixRadioButton>
                <PixRadioButton
                  name="birth-geo-code-option"
                  checked={{if (eq this.selectedBirthGeoCodeOption "postal") true}}
                  {{on "change" (fn this.selectBirthGeoCodeOption "postal")}}
                  required
                >
                  <:label>Code postal</:label>
                </PixRadioButton>
              </div>
            </div>
          {{/if}}

          {{#if this.isBirthInseeCodeRequired}}
            <div class="candidate-edit-modal--content__field">
              <PixInput
                class="input"
                @value={{this.birthInseeCode}}
                required
                {{on "input" (pick "target.value" (set this "birthInseeCode"))}}
              ><:label>
                  Code Insee de naissance
                </:label></PixInput>
            </div>
          {{/if}}

          {{#if this.isBirthPostalCodeRequired}}
            <div class="candidate-edit-modal--content__field">
              <PixInput
                class="input"
                @value={{this.birthPostalCode}}
                required
                {{on "input" (pick "target.value" (set this "birthPostalCode"))}}
              ><:label>
                  Code postal de naissance
                </:label></PixInput>
            </div>
          {{/if}}

          {{#if this.isBirthCityRequired}}
            <div class="candidate-edit-modal--content__field">
              <PixInput
                class="input"
                @value={{this.birthCity}}
                required
                {{on "input" (pick "target.value" (set this "birthCity"))}}
              ><:label>
                  Commune de naissance
                </:label></PixInput>
            </div>
          {{/if}}
        </form>
      </:content>

      <:footer>
        <PixButton @size="small" @variant="secondary" @triggerAction={{this.onCancelButtonsClicked}}>
          Annuler
        </PixButton>
        <PixButton form="candidate-edit-form" @size="small" @type="submit">
          Enregistrer
        </PixButton>
      </:footer>
    </PixModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
