import { template } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ObjectRequirementForm from 'pix-admin/components/quests/requirements/object/form';
import { objectConfigurations } from 'pix-admin/components/quests/requirements/object/object-configuration.js';
import PageTitle from '../../ui/page-title';
import SnippetList from '../snippets/list';
const LOCAL_STORAGE_KEY = 'QUEST_REQUIREMENT_SNIPPETS';
export default class RequirementForm extends Component {
    @service
    pixToast;
    @service
    router;
    @tracked
    selectedRequirementType = null;
    @tracked
    requirementLabel = null;
    @tracked
    requirementComparison = null;
    @tracked
    requirementFields = null;
    @tracked
    formFields = null;
    get requirementTypeOptions() {
        console.log(Object.values(objectConfigurations));
        return Object.values(objectConfigurations).map(({ name: name1, label: label1 })=>({
                value: name1,
                label: label1
            }));
    }
    get configurationForSelectedRequirement() {
        return objectConfigurations[this.selectedRequirementType];
    }
    @action
    updateLabel(event1) {
        this.requirementLabel = event1.target.value;
    }
    @action
    updateComparison(value1) {
        this.requirementComparison = value1;
    }
    @action
    editRequirement(label1) {
        const snippets1 = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {
            objectRequirementsByLabel: {}
        };
        this.requirementLabel = label1;
        this.requirementComparison = snippets1.objectRequirementsByLabel[label1].comparison;
        this.requirementFields = snippets1.objectRequirementsByLabel[label1].data;
        this.updateRequirementType(snippets1.objectRequirementsByLabel[label1].requirement_type, true);
    }
    @action
    updateRequirementType(value1, isEditing1 = false) {
        if (!isEditing1) this.resetRequirement();
        this.selectedRequirementType = value1;
        this.requirementComparison = objectConfigurations[this.selectedRequirementType].mergeFields ? 'all' : this.requirementComparison;
        this.formFields = objectConfigurations[this.selectedRequirementType].fieldConfigurations.map((fieldConfiguration1)=>{
            const field1 = isEditing1 && this.requirementFields[fieldConfiguration1.name];
            return {
                name: fieldConfiguration1.name,
                comparison: isEditing1 && field1 ? field1.comparison : undefined,
                data: isEditing1 && field1 ? field1.data : undefined
            };
        });
    }
    @action
    resetRequirement() {
        this.requirementComparison = null;
        this.requirementLabel = null;
        this.requirementFields = null;
    }
    @action
    updateField({ name: name1, comparison: comparison1, value: value1 }) {
        const field1 = this.formFields.find((field1)=>field1.name === name1);
        field1.comparison = comparison1;
        field1.data = value1;
    }
    @action
    saveSnippet(event1) {
        event1.preventDefault();
        try {
            const requirement1 = this.configurationForSelectedRequirement.buildRequirementFromFormValues(this.requirementComparison, this.formFields);
            const snippets1 = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {
                objectRequirementsByLabel: {}
            };
            snippets1.objectRequirementsByLabel[this.requirementLabel] = requirement1;
            window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(snippets1));
            this.pixToast.sendSuccessNotification({
                message: 'Le requirement est ajouté dans le local storage.'
            });
            this.selectedRequirementType = null;
            this.resetRequirement();
        } catch (error1) {
            console.log(error1);
            this.pixToast.sendErrorNotification({
                message: "Le requirement n'a pas pu être ajouté."
            });
        }
    }
    static{
        template(`
    <PageTitle>
      <:title>Créer ou Éditer un "requirement" de quête</:title>
    </PageTitle>
    <section class="quest-object-form">
      <PixBlock @variant="admin" class="quest-requirement-form">
        <PixSelect
          class="quest-requirement-form__item-width-content"
          @onChange={{this.updateRequirementType}}
          @value={{this.selectedRequirementType}}
          @options={{this.requirementTypeOptions}}
          @placeholder="Choisissez votre type de requirement"
          @hideDefaultOption={{true}}
        >
          <:label>Sélectionner un type de requirement</:label>
        </PixSelect>

        <div>
          {{#if this.selectedRequirementType}}
            <ObjectRequirementForm
              @configuration={{this.configurationForSelectedRequirement}}
              @requirementLabel={{this.requirementLabel}}
              @requirementComparison={{this.requirementComparison}}
              @requirementFields={{this.requirementFields}}
              @updateLabel={{this.updateLabel}}
              @updateField={{this.updateField}}
              @updateComparison={{this.updateComparison}}
              @saveSnippet={{this.saveSnippet}}
              @formFields={{this.formFields}}
            />
          {{/if}}
        </div>
      </PixBlock>

      <PixBlock @variant="admin" class="quest-button-edition quest-button-edition--column">
        <h2>Editer mes snippets :</h2>
        <SnippetList @triggerAction={{this.editRequirement}} />
      </PixBlock>

      <div class="quest-button-edition__button">
        <PixButtonLink @route="authenticated.quest-creator" @size="small" @variant="primary">
          Revenir au formulaire de quête
        </PixButtonLink>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
