import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixModal from '@1024pix/pix-ui/components/pix-modal';
import PixTable from '@1024pix/pix-ui/components/pix-table';
import PixTableColumn from '@1024pix/pix-ui/components/pix-table-column';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import pick from 'ember-composable-helpers/helpers/pick';
import { t } from 'ember-intl';
import set from 'ember-set-helper/helpers/set';
import { not } from 'ember-truth-helpers';
import uniq from 'lodash/uniq';
export default class OrganizationTargetProfilesSectionComponent extends Component {
    @tracked
    targetProfilesToAttach = '';
    @tracked
    showModal = false;
    @tracked
    targetProfileToDetach;
    @service
    accessControl;
    @service
    pixToast;
    @service
    router;
    @service
    store;
    @service
    intl;
    get isDisabled() {
        return this.targetProfilesToAttach === '';
    }
    get sortedTargetProfileSummaries() {
        return [
            ...this.args.targetProfileSummaries
        ].sort((a1, b1)=>Number(a1.id) - Number(b1.id));
    }
    @action
    canDetachTargetProfile({ canDetach: canDetach1 }) {
        return canDetach1;
    }
    @action
    async attachTargetProfiles(e1) {
        e1.preventDefault();
        if (this.isDisabled) return;
        const organization1 = this.args.organization;
        const targetProfileSummaries1 = this.args.targetProfileSummaries;
        try {
            const targetProfileIdsBefore1 = targetProfileSummaries1.map(({ id: id1 })=>id1);
            const targetProfileIdsToAttach1 = this._getUniqueTargetProfileIds();
            const adapter1 = this.store.adapterFor('organization');
            await adapter1.attachTargetProfile({
                organizationId: organization1.id,
                targetProfileIds: targetProfileIdsToAttach1
            });
            await targetProfileSummaries1.reload();
            const targetProfileIdsAfter1 = targetProfileSummaries1.map(({ id: id1 })=>id1);
            const attachedIds1 = targetProfileIdsAfter1.filter((id1)=>!targetProfileIdsBefore1.includes(id1));
            const duplicatedIds1 = targetProfileIdsBefore1.filter((id1)=>targetProfileIdsToAttach1.includes(id1));
            const hasInserted1 = attachedIds1.length > 0;
            const hasDuplicated1 = duplicatedIds1.length > 0;
            const message1 = [];
            if (hasInserted1) {
                message1.push('Profil(s) cible(s) rattaché(s) avec succès.');
            }
            if (hasInserted1 && hasDuplicated1) {
                message1.push('<br/>');
            }
            if (hasDuplicated1) {
                message1.push(`Le(s) profil(s) cible(s) suivant(s) étai(en)t déjà rattaché(s) à cette organisation : ${duplicatedIds1.join(', ')}.`);
            }
            this.targetProfilesToAttach = '';
            return this.pixToast.sendSuccessNotification({
                message: message1.join('')
            });
        } catch (responseError1) {
            this._handleResponseError(responseError1);
        }
    }
    @action
    goToTargetProfilePage(targetProfileId1) {
        this.router.transitionTo('authenticated.target-profiles.target-profile', targetProfileId1);
    }
    @action
    async detachTargetProfile(targetProfilId1) {
        const adapter1 = this.store.adapterFor('target-profile');
        try {
            await adapter1.detachOrganizations(targetProfilId1, [
                this.args.organization.id
            ]);
            this.closeModal();
            await this.args.organization.get('targetProfileSummaries').reload();
            return this.pixToast.sendSuccessNotification({
                message: 'Profil cible détaché avec succès.'
            });
        } catch (responseError1) {
            this._handleResponseError(responseError1);
        }
    }
    @action
    openModal(targetProfile1) {
        this.showModal = true;
        this.targetProfileToDetach = targetProfile1;
    }
    @action
    closeModal() {
        this.showModal = false;
        this.targetProfileToDetach = null;
    }
    _handleResponseError({ errors: errors1 }) {
        const genericErrorMessage1 = this.intl.t('common.notifications.generic-error');
        if (!errors1) {
            return this.pixToast.sendErrorNotification({
                message: genericErrorMessage1
            });
        }
        errors1.forEach((error1)=>{
            if ([
                '404',
                '412'
            ].includes(error1.status)) {
                return this.pixToast.sendErrorNotification({
                    message: error1.detail
                });
            }
            return this.pixToast.sendErrorNotification({
                message: genericErrorMessage1
            });
        });
    }
    _getUniqueTargetProfileIds() {
        const targetProfileIds1 = this.targetProfilesToAttach.split(',').map((targetProfileId1)=>targetProfileId1.trim());
        return uniq(targetProfileIds1);
    }
    static{
        template(`
    {{#if this.accessControl.hasAccessToOrganizationActionsScope}}
      <section class="page-section">
        <header class="page-section__header">
          <h2 class="page-section__title">Rattacher un ou plusieurs profil(s) cible(s)</h2>
        </header>
        <div class="organization__forms-section">
          <form class="organization__sub-form form" {{on "submit" this.attachTargetProfiles}}>
            <PixInput
              aria-label="ID du ou des profil(s) cible(s)"
              @value={{this.targetProfilesToAttach}}
              class="organization-sub-form__input form-field__text form-control"
              placeholder="1, 2"
              {{on "input" (pick "target.value" (set this "targetProfilesToAttach"))}}
            />
            <PixButton @type="submit" @size="small" @isDisabled={{this.isDisabled}}>
              {{t "common.actions.validate"}}
            </PixButton>
          </form>
        </div>
      </section>
    {{/if}}

    <section class="page-section">
      <header class="page-section__header">
        <h2 class="page-section__title">Profils cibles</h2>
      </header>
      {{#if @targetProfileSummaries}}
        <PixTable
          @variant="admin"
          @caption={{t "components.organizations.target-profiles-section.table.caption"}}
          @data={{this.sortedTargetProfileSummaries}}
        >
          <:columns as |summary context|>
            <PixTableColumn @context={{context}}>
              <:header>
                ID
              </:header>
              <:cell>
                {{summary.id}}
              </:cell>
            </PixTableColumn>
            <PixTableColumn @context={{context}} class="break-word">
              <:header>
                Nom interne du profil cible
              </:header>
              <:cell>
                <LinkTo @route="authenticated.target-profiles.target-profile" @model={{summary.id}}>
                  {{summary.internalName}}
                </LinkTo>
              </:cell>
            </PixTableColumn>
            {{#if this.accessControl.hasAccessToOrganizationActionsScope}}
              <PixTableColumn @context={{context}}>
                <:header>
                  Actions
                </:header>
                <:cell>
                  {{#if (this.canDetachTargetProfile summary)}}
                    <PixButton @variant="error" @size="small" @triggerAction={{fn this.openModal summary}}>
                      Détacher
                    </PixButton>
                  {{/if}}
                </:cell>
              </PixTableColumn>
            {{/if}}
          </:columns>
        </PixTable>
      {{else}}
        <div class="table__empty">{{t "common.tables.empty-result"}}</div>
      {{/if}}
    </section>

    <PixModal
      @title="Détacher le profil cible de l'organisation"
      @onCloseButtonClick={{this.closeModal}}
      @showModal={{this.showModal}}
      aria-hidden="{{not this.showModal}}"
    >
      <:content>
        <p>
          Etes-vous sûr de vouloir détacher le profil cible
          <strong>{{this.targetProfileToDetach.name}}</strong>
          de l'organisation
          <strong>{{@targetProfileName}}</strong>
          ?
        </p>
      </:content>
      <:footer>
        <PixButton @variant="secondary" @triggerAction={{this.closeModal}}>
          {{t "common.actions.cancel"}}
        </PixButton>
        <PixButton
          @variant="error"
          @triggerAction={{fn this.detachTargetProfile this.targetProfileToDetach.id}}
        >Confirmer</PixButton>
      </:footer>
    </PixModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
