import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import PixTextarea from '@1024pix/pix-ui/components/pix-textarea';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { optionsCategoryList } from '../../models/target-profile';
import Card from '../card';
import TubesSelection from '../common/tubes-selection';
export default class EditTargetProfileForm extends Component {
    @tracked
    submitting = false;
    selectedTubes = [];
    constructor(){
        super(...arguments);
        this.optionsList = optionsCategoryList;
    }
    @action
    updateTubes(tubesWithLevel1) {
        this.selectedTubes = tubesWithLevel1.map(({ id: id1, level: level1 })=>({
                id: id1,
                level: level1
            }));
    }
    @action
    handleInputValue(key1, event1) {
        this.args.targetProfile[key1] = event1.target.value;
    }
    @action
    handleSelectChange(key1, value1) {
        this.args.targetProfile[key1] = value1;
    }
    @action
    handleCheckboxChange(key1, event1) {
        this.args.targetProfile[key1] = event1.target.checked;
    }
    @action
    async onSubmit(event1) {
        event1.preventDefault();
        try {
            this.submitting = true;
            await this.args.onSubmit(event1, this.selectedTubes);
        } finally{
            this.submitting = false;
        }
    }
    static{
        template(`
    <form class="admin-form">
      <p class="admin-form__mandatory-text">
        {{t "common.forms.mandatory-fields" htmlSafe=true}}
      </p>
      <section class="admin-form__content admin-form__content--with-counters">

        <Card class="admin-form__card" @title="Information sur le profil cible">
          <PixInput
            @id="targetProfileInternalName"
            required={{true}}
            @requiredLabel={{t "common.forms.mandatory"}}
            aria-required={{true}}
            @value={{@targetProfile.internalName}}
            {{on "change" (fn this.handleInputValue "internalName")}}
          >
            <:label>Nom interne :</:label>
          </PixInput>

          <PixInput
            @id="targetProfileName"
            required={{true}}
            @requiredLabel={{t "common.forms.mandatory"}}
            aria-required={{true}}
            @value={{@targetProfile.name}}
            {{on "change" (fn this.handleInputValue "name")}}
          >
            <:label>Nom externe :</:label>
          </PixInput>

          <PixSelect
            @onChange={{fn this.handleSelectChange "category"}}
            @value={{@targetProfile.category}}
            @options={{this.optionsList}}
            @placeholder="-"
            @hideDefaultOption={{true}}
            required={{true}}
            @requiredLabel={{t "common.forms.mandatory"}}
            aria-required={{true}}
          >
            <:label>Catégorie :</:label>
          </PixSelect>

          {{#unless @updateMode}}
            <PixInput
              @id="organizationId"
              type="number"
              @errorMessage=""
              required={{true}}
              @requiredLabel={{t "common.forms.mandatory"}}
              aria-required={{true}}
              placeholder="7777"
              value={{@targetProfile.ownerOrganizationId}}
              {{on "change" (fn this.handleInputValue "ownerOrganizationId")}}
            >
              <:label>Identifiant de l'organisation de référence :</:label>
            </PixInput>
          {{/unless}}

          <PixCheckbox
            @checked={{@targetProfile.areKnowledgeElementsResettable}}
            onChange={{fn this.handleCheckboxChange "areKnowledgeElementsResettable"}}
          >
            <:label>{{t "pages.target-profiles.resettable-checkbox.label"}}</:label>
          </PixCheckbox>
        </Card>

        {{#if @targetProfile.hasLinkedCampaign}}
          <PixNotificationAlert @withIcon={{true}}>
            Le référentiel n'est pas modifiable car le profil cible est déjà relié à une campagne.
          </PixNotificationAlert>
        {{else}}
          <TubesSelection
            @frameworks={{@frameworks}}
            @initialCappedTubes={{@targetProfile.cappedTubes}}
            @initialAreas={{@targetProfile.areas}}
            @onChange={{this.updateTubes}}
            @displayJsonImportButton={{true}}
            @displayDeviceCompatibility={{true}}
            @displaySkillDifficultyAvailability={{true}}
          />
        {{/if}}

        <Card class="admin-form__card" @title="Personnalisation">
          <PixInput
            @id="imageUrl"
            @subLabel="L'url à saisir doit être celle d'OVH. Veuillez
              vous rapprocher des équipes tech et produit pour la réalisation de celle-ci."
            value={{@targetProfile.imageUrl}}
            {{on "change" (fn this.handleInputValue "imageUrl")}}
          >
            <:label>Lien de l'image du profil cible :</:label>
          </PixInput>

          <PixTextarea
            @id="description"
            @maxlength="500"
            rows="4"
            @value={{@targetProfile.description}}
            {{on "change" (fn this.handleInputValue "description")}}
          >
            <:label>Description :</:label>
          </PixTextarea>
          <PixTextarea
            @id="comment"
            @maxlength="500"
            rows="4"
            @value={{@targetProfile.comment}}
            {{on "change" (fn this.handleInputValue "comment")}}
          >
            <:label>Commentaire (usage interne) :</:label>
          </PixTextarea>
        </Card>
      </section>

      <section class="admin-form__actions">
        <PixButton @variant="secondary" @size="large" @triggerAction={{@onCancel}}>
          {{t "common.actions.cancel"}}
        </PixButton>
        <PixButton
          @variant="success"
          @size="large"
          @type="submit"
          @isLoading={{this.submitting}}
          @triggerAction={{this.onSubmit}}
        >
          {{#if @updateMode}}
            Modifier le profil cible
          {{else}}
            Créer le profil cible
          {{/if}}
        </PixButton>
      </section>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
