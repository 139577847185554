import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
export default class extends Component {
    @service
    session;
    @service
    fileSaver;
    @action
    async downloadTemplate() {
        try {
            const url1 = ENV.APP.API_HOST + this.args.url;
            const token1 = this.session.data.authenticated.access_token;
            await this.fileSaver.save({
                url: url1,
                token: token1
            });
        } catch (error1) {
            this.pixToast.sendErrorNotification({
                message: error1.message
            });
        }
    }
    static{
        template(`
    <div class="csv-import" ...attributes>
      {{yield}}
      <PixButton @triggerAction={{this.downloadTemplate}} @variant="tertiary">
        <PixIcon @name="download" @plainIcon={{true}} @ariaHidden={{true}} />
        {{t "common.actions.download-template"}}
      </PixButton>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
